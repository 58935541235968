import { initializeApp, getApp } from 'firebase/app';
import { httpsCallable, getFunctions, connectFunctionsEmulator } from 'firebase/functions';

let timeoutId: ReturnType<typeof setTimeout> | undefined;

const showToast = async (message: string, isError = false) => {
  // Clear the existing timeout if it exists
  if (timeoutId !== undefined) {
    clearTimeout(timeoutId);
  }
  // Find the toast element and set 
  const toast = document.querySelector('.toast') as HTMLElement;

  // If the toast is visible, hide it
  if (toast.classList.contains('show')) {
    toast.classList.remove('show');
    // Wait for the hide animation to finish
    await new Promise((resolve) => {
      toast.addEventListener('transitionend', resolve, { once: true });
    });
  }

  // Set the message and show the toast
  toast.textContent = message;
  toast.classList.toggle('error', isError);
  toast.classList.add('show');

  // Set a timeout to hide the toast after 5 seconds
  timeoutId = setTimeout(() => {
    toast.classList.remove('show');
  }, 5000);
};

const submitForm = async (form: HTMLFormElement) => {
  showToast('Sending your message...');

  // Get the form data, then get form controls by name
  const formData = new FormData(form);
  const name = formData.get('name') as string;
  const email = formData.get('email') as string;
  const phone = formData.get('phone') as string;
  const message = formData.get('message') as string;

  // console.log('Sending email:', { name, email, phone, message });

  // Initialize Firebase
  const app = initializeApp({ 
    apiKey: "AIzaSyBRin61aINMxtcFCqxdmHDWB-mBLQGpgyo",
    appId: "1:216760873083:web:f9a726018ae15eb27f72de",
    measurementId: "G-37QK73T1CK",
    projectId: "cando-consulting",
  });
  const functions = getFunctions(app);
  // Connect your app to the Cloud Functions Emulator running on localhost for local testing.
  // When running locally, Cloud Functions will be available at http://localhost:5001/<projectId>/<region>/<functionName>
  // Learn more about testing Cloud Functions locally: https://firebase.google.com/docs/emulator-suite/connect_functions
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  }
  const sendEmail = httpsCallable(functions, 'sendEmail');
  
  sendEmail({ name, email, phone, message }).then((result) => {
    form.reset();
    showToast('Message sent successfully!');
  }).catch((error) => {
    showToast(`Error sending message: ${error.message}`, true);
  });
};

// Wait for the DOM to be fully loaded
document.addEventListener('DOMContentLoaded', function(event) {
  const form = document.getElementById('contact-form') as HTMLFormElement;

  // Add a submit event listener to the form
  form.addEventListener('submit', (event) => {
    event.preventDefault();
    // Prevent form submission if there are invalid fields
    if (!form.checkValidity()) {
      form.classList.add('invalid');
    } else {
      form.classList.remove('invalid');
      submitForm(form);
    }
  });
});